import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { withTheme } from 'styled-components'
import Select from 'react-select';

const options = [
  { value: 'https://drive.google.com/file/d/1KU-vmnwto4wYe64AnHbrSryi1lOacg4B/preview', label: 'Trev' },
  { value: 'https://drive.google.com/file/d/1LXEGVH3tncu7Ucf7PKDHxjhtcT06yJfX/preview', label: 'Carson, Taylor & Kerrington' },
  { value: 'https://drive.google.com/file/d/1-RxLn9HZc2pkT877PFE56E-YQvzJ2v8Z/preview', label: 'Jess & Jens' },
  { value: 'https://www.youtube.com/embed/bXnNe6B_tIU', label: 'Matthew, Leigh & Adrian' },
  { value: 'https://www.youtube.com/embed/_uOSA51MZro', label: 'Cale & Hannah' },
  { value: 'https://www.youtube.com/embed/WzVSeKzmwVI', label: 'Johann & Doug' },
  { value: 'https://www.youtube.com/embed/btTqUkba4b4', label: 'Pureum, Saet-byeol & Simon' },
  { value: 'https://www.youtube.com/embed/TLHfwJTbn_M', label: 'Avi & Guilherme' }
];

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: state.isSelected ? 'white' : 'black',
    border: state.isFocused ? '0e5144' : 'none',
    boxShadow: '0 !important',
    padding: 20,
    background: state.isSelected ? '#19927b' : 'white',
    '&:hover': {
      background: '#0e5144',
      color: "white"
    }
  }),
  control: (provided, state) => ({
    ...provided,
    // This line disable the blue border
    border: '1px solid #0e5144',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #0e5144'
    }
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

class Films extends React.Component {
  state = {
    selectedOption: { value: 'https://drive.google.com/file/d/1KU-vmnwto4wYe64AnHbrSryi1lOacg4B/preview', label: 'Trev' },
  }
  handleChange = selectedOption => {
    this.setState({ selectedOption });
    let film = document.getElementById("filmEmbed")
    console.log(film)
    film.setAttribute("src", selectedOption.value)
    console.log(film)
    console.log(selectedOption.value)
  };

  render() {
    const { data } = this.props
    const { selectedOption } = this.state;
    const siteTitle = data.site.siteMetadata.title
    const AboutWrapper = styled.div`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /*width: 80%;
      height: 80%;*/
    `;

    const AboutItems = styled.div`
      width: 100%;
      height: 100%;
      /*padding: 1em;*/
      margin: .5em;
      font-family: Montserrat, sans-serif;
    `;


    return (
      <Layout location={`/Films`} title={siteTitle}>
        <SEO title="Vancouver Ivanhoe Film Festival (VIFF)" />
        <AboutWrapper>
          <AboutItems>
          <p>On May 31st 2019m, for my 31st birthday, I had a 31 hour film festival. And it was amazing.</p>
          <p>Eight fantastic films were created, and later watched, with the windows wide open in my packed and hot apartment (the Ivanhoe). 
            Drama, comedy, poetry, etc. It was high art at its most sky high. </p>
          <p>Whose film do you want to watch?</p>
            <Select
              styles={customStyles}
              value={selectedOption}
              onChange={this.handleChange}
              options={options}
            />
            <p></p>
          <iframe id="filmEmbed" src={this.state.selectedOption.value} width="100%" height="500px"></iframe>
          </AboutItems>
        </AboutWrapper>
        
      </Layout>
    )
  }
}

export default Films

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

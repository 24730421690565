import React from "react"
import { Link } from "gatsby"
import styles from "../styles.css"
import {isMobile} from 'react-device-detect';
import Sidebar from "react-sidebar";
import { rhythm, scale } from "../utils/typography"
import MenuIcon from "./menuicon.js"
import MenuList from "./menu.js"
import CloseIcon from "../../content/assets/close-icon.png"
import Helmet from 'react-helmet'

class Layout extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      
      showMenu: false, 
      style: {
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
      }
    };
    this.toggleMenu = this.toggleMenu.bind(this);
  }

  toggleMenu() {
    if (this.state.showMenu == false) {
      this.setState({showMenu: true})
      document.body.style.background = `#FFC2AA`;
    } else {
      this.setState({showMenu: false})
      document.body.style.background = `white`
    }
  }

  componentDidMount() {
    if (isMobile == true) {
      this.setState({
        style: {
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`
        }
      })
    } else {
      this.setState({
        style: {
          marginLeft: `auto`,
          marginRight: `auto`,
          paddingLeft: `25vw`,
          paddingRight: `25vw`,
          paddingTop: `5vh`,
          paddingBottom: `5vh`
        }
      })
    }
  }

  render() {
    const { location, title, children } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    let header
    
    
      header = (
        <h2
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h2>
      )

    return (
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          marginTop: `50px`,
          maxWidth: '100%',
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
      <div id="comm100-button-77"></div>
        <Sidebar
          sidebar={
            <div style={{
              background: `white`,
              width: `100%`,
              height: `100%`,
              display: `flex`,
              alignItems: `center`,
              justifyContent: `center`,
              flexDirection: `column`,
              fontFamily: `Montserrat, sans-serif`,
              fontSize: `30px`
              }}>
              <p><Link onClick={this.toggleMenu} to={`/`}>Blog</Link></p>
              <p><Link onClick={this.toggleMenu} to={`about-me`}>About Me</Link></p>
              <p><Link onClick={this.toggleMenu} to={`projects`}>Projects</Link></p>
              <p><Link onClick={this.toggleMenu} to={`films`}>Film Fest</Link></p>
              <div id="CloseButton">
                <img 
                  src={CloseIcon}
                  onClick={this.toggleMenu}
                />
              </div>
            </div>
            }
          pullRight={true}
          open={this.state.showMenu}
          onSetOpen={this.toggleMenu}
          styles={{ sidebar: { 
            background: `white`,
            width: `100%`,
            height: `100%`,
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            flexDirection: `column`,
            fontFamily: `Montserrat, sans-serif`,
            fontSize: `30px`
              },
              content: this.state.style
            }}
        >

        {this.state.showMenu == false && <MenuIcon id="MenuIcon" toggleMenu={this.toggleMenu}/>}
          <header>{header}</header>
          <main className="mainColumnReal" >{children}</main>
        </Sidebar>
       



  






      </div>
    )
  }
}



export default Layout
